export enum ExistingHeatingType {
  ELECTRIC = 'electric_heating',
  GAS = 'gas',
  OIL = 'oil',
}

export enum ExistingHeatingGasType {
  CondensingGasBoiler = 'condensing_gas_boiler',
  NonCondensingGasBoiler = 'non_condensing_gas_boiler',
  GasHeating = 'gas_heating',
  RadiatorGasHeating = 'radiator_gas_heating',
  StoveGasHeating = 'stove_gas_heating',
}

export enum VulcanSanitaryWaterHeatingValues {
  Heating = 'heating',
  HeatingAndWater = 'heating_and_water',
}

export enum WorksType {
  Insulation = 'insulation',
  AirWaterHeatPump = 'air_water_heat_pump',
  PhotovoltaicPanel = 'photovoltaic_panel',
}

export enum ProjectStatus {
  ReflectionWorks = 'reflection_works',
  LookingForArtisan = 'looking_for_an_artisan',
  WillSignQuote = 'will_sign_quote',
  QuoteSigned = 'quote_signed',
}

export enum UserStatus {
  OwnerOccupied = 'resident_owner',
  SecondHome = 'secondary_residence_owner',
  OwnerLessor = 'leasing_owner',
  Tenant = 'tenant',
}

export enum TimingProject {
  Asap = 'asap',
  Next6Months = 'next6Months',
  DontKnow = 'dontKnow',
}

export enum HouseholdIncome {
  VeryModest = 'very_modest',
  Modest = 'modest',
  Standard = 'standard',
  High = 'high',
}

export enum AnswserKeys {
  HousingType = 'housing_type',
  HousingAge = 'housing_age',
  HousingSurface = 'housing_surface',
  ExistingEnergy = 'existing_energy',
  ExistingHeatingType = 'existing_energy_type',
  WorksType = 'works_type',
  InsulationType = 'insulation_type',
  AtticsType = 'attics_type',
  AtticsSurface = 'attics_surface',
  AtticsInsulationType = 'attics_insulation_type',
  WallsSurface = 'walls_surface',
  AirWaterHeatPumpSurface = 'air_water_heat_pump_surface',
  AirWaterHeatPumpSanitaryWater = 'air_water_heat_pump_sanitary_water',
  AirWaterHeatPumpExteriorSpace = 'air_water_heat_pump_exterior_space',
  ProjectStatus = 'project_status',
  TimingProject = 'timing_project',
  UserStatus = 'user_status',
  HouseholdComposition = 'household_composition',
  HouseholdIncome = 'household_income',
  UserCivility = 'user_civility',
  UserFirstname = 'user_firstname',
  UserLastname = 'user_lastname',
  UserPhone = 'user_phone',
  WorksAddress = 'works_address',
  HousingZipCode = 'housing_zip_code',
}
